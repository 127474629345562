@tailwind base;

html {
    scroll-behavior: smooth;
}

@tailwind components;
@tailwind utilities;

/* custom.css */
.custom-scrollbar {
    overflow-x: auto;
    scrollbar-color: #00ffff transparent;
}